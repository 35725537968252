import React from 'react'
import Navbar from '../components/NavBar/Navbar'
import Header from '../components/Header/Header'
import About from '../components/About/About'
import Projects from '../components/Projects/Projects'
import Tech from '../components/Tech/Tech'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import ScrollButton from '../components/buttons/ScrollButton'
import { Wrapper } from './HomeStyles'

const Home = ({theme, setTheme}) => {

  document.title = "Zamir Cohen´s Portfolio"


  return (
    <div>
        <Wrapper>
        <Navbar theme={theme} setTheme={setTheme}/>
        <Header />
        <About />
        <Projects />
        <Tech />
        <Contact />
        <ScrollButton /> 
        <Footer />
        </Wrapper>
    </div>
  )
}

export default Home