import React, { useRef, useState } from "react";
import {
  Section,
  SectionTitle,
} from "../../styles/GlobalComponentStyles";
import { Wrapper, ContactStyles } from "./ContactStyles";
import emailjs from "emailjs-com";
import { Fade } from "react-awesome-reveal";

export default function Contact() {
  const initialTitle = "Contact me.";
  const [formTitle, setFormTitle] = useState(initialTitle);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          document.getElementById("contact-form").style.display = "hidden";
          setFormTitle("Thank you!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Wrapper contentcenter row id="contact">
      <Fade delay={300} triggerOnce={true}>
        <ContactStyles>
          <Section>
            <SectionTitle main center color id="contact-title">
              {formTitle}
            </SectionTitle>
            <form ref={form} id="contact-form" className="form" onSubmit={sendEmail}>
              <input
                className="input"
                type="text"
                placeholder=" Your name*"
                name="name"
                required
              />
              <input
                className="input"
                type="text"
                placeholder=" Your email*"
                name="email"
              />
              <input
                className="input"
                type="text"
                placeholder="Company"
                name="company"
              />
              <input
                className="input"
                type="tel"
                placeholder=" Phone number"
                name="phone"
              />
              <textarea
                className="textarea"
                placeholder="Your message..."
                name="message"
                maxLength={200}
              ></textarea>
              <br />
              <button className="button" type="submit">
                Send
              </button>
            </form>
          </Section>
        </ContactStyles>
      </Fade>
    </Wrapper>
  );
}
