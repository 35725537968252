 export const light = {
    background: '#ffffff',
    color: '#000000',
    buttonBg: '#c5718d',
    transition: 'all 0.50s linear',
    card: 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)',
    shadow: '0 0 20px 0 rgba(0, 0, 0, 0.5)',
    linkcolor: '#531fff',
    linkcolor2: '#1b0469',
    menubackground: '#61B9E9',
    hover: '#00FFFF',
}

export const dark = {
    background: '#000000',
    color: '#ffffff',
    buttonBg: '#515d90',
    transition: 'all 0.50s linear',
    card: 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)',
    shadow: '0 0 20px 0 rgba(0,255,255,0.7), 0 0 20px 0 rgba(0,255,255,0.7)', 
    linkcolor: '#00FFFF',
    linkcolor2: '#04b3b3',
    menubackground: '#61B9E9',
    hover: '#00FFFF',
}

