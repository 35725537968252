import React from 'react';
import { FaGithub, FaLinkedin, FaFacebook, FaSun, FaMoon} from 'react-icons/fa';
import ToggleButton from '../buttons/ToggleButton';
// import Lottie from 'react-lottie';
// import animationData from '../../lotties/home';
import {
  Nav,
  NavLink,
  NavLogo,
  NavMenu,
  NavSocialMedia,
  SocialLink,
  // DropdownMenu,
} from './NavbarStyles';
// import { ToggleBackground } from './NavbarStyles';
// import MenuButton from '../buttons/MenuButton';
import * as Scroll from 'react-scroll';

const Navbar = ({theme, setTheme}) => {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  // const defaultOptions = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: animationData,
  //   hover: true,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  // };

  // const [toggle, setToggle] = React.useState(false);

  // const toggleBackground = () => {
  //   setToggle(!toggle);
  // };


  return (
    <>
      {/* {toggle ? <ToggleBackground /> : null } */}
      <Nav>

        <NavLogo>
        <FaSun /> <ToggleButton theme={theme} setTheme={setTheme}/> <FaMoon />
        </NavLogo>

        <NavMenu>
          <NavLink activeStyle>
            <Scroll.Link onClick={scrollToTop} smooth={true} duration={500}>
            {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
            Home
            </Scroll.Link>
          </NavLink>

          <NavLink to='/about' activeStyle>
          <Scroll.Link to='about' smooth={true} duration={500}>
            About
          </Scroll.Link>
          </NavLink>

          <NavLink to='/projects' activeStyle>
          <Scroll.Link to='projects' smooth={true} duration={500}>
            Projects
          </Scroll.Link>
          </NavLink>

          <NavLink to='/tech' activeStyle>
          <Scroll.Link to='tech' smooth={true} duration={500}>
            Tech
          </Scroll.Link>
          </NavLink>

          <NavLink to='/contact' activeStyle>
          <Scroll.Link to='contact' smooth={true} duration={500}>
            Contact
          </Scroll.Link>
          </NavLink>
        </NavMenu>

        <NavSocialMedia>
          <SocialLink href='https://github.com/zamircohen' target='_blank'>
            <FaGithub />
          </SocialLink>
          <SocialLink href='https://www.linkedin.com/in/zamircohen/' target='_blank'>
            <FaLinkedin />
          </SocialLink>
          <SocialLink href='https://www.facebook.com/zamircohen/' target='_blank' >
            <FaFacebook />
          </SocialLink>
        </NavSocialMedia>

        {/* <DropdownMenu onClick={toggleBackground} >
          <MenuButton />
        </DropdownMenu> */}
        
      </Nav>
    </>
  );
};
  
export default Navbar;