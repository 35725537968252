import styled from 'styled-components';

export const ToggleButtonStyles = styled.div`
  .toggle-switch {
    position: relative;
    display: inline-block;
    min-width: 1.6rem;
    min-height: .8rem;
    width: 2vw;
    height: 1vw;
    margin: 0 1vh 0 1vh;
    background-color: transparent;;  
  }
  .toggle-switch input[type='checkbox'] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  
  .toggle-switch .switch::before {
    position: absolute;
    content: '';
    width: 1vw;
    height: 1vw;
    min-width: .8rem;
    min-height: .8rem;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(1vw);
    background-color: #6699cc;

    @media screen and (max-width: 768px) {
      transform: translateX(10px);
  }
  .toggle-switch input[type='checkbox']:checked + .switch {
    background-color: #336699;
  }
`;


