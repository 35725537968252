import React from 'react'
import { Wrapper, Section, SectionTitle, Card, CardSection, CardTitle, CardText, CardTech, ProjectText} from '../../styles/GlobalComponentStyles';
import { Fade } from "react-awesome-reveal";


const projects = [
  {
     "title": "Chat app",
     "image": '/images/chatapp.jpg',
     "alt": "Chat app",
     "description":"A simple chat application that allows you to chat with other users.",
     "tools": "TypeScript, NodeJS, React, CSS, HTML, MongoDB",
     "link": "https://github.com/zamircohen/fullstack-chat-app"
  },

  {
    "title": "E-com",
    "image": '/images/e-com.jpg',
    "alt": "E-commerce",
    "description":"Simple e-commerce application. Group assignment with two co-workers.",
    "tools": "TypeScript, NodeJS, React, CSS, HTML, MongoDB",
    "link": "https://github.com/MiranKorkmaz/e-commerce"
 },

 {
  "title": "Coming soon...",
  "image": '/images/code.jpg',
  "description":"Coming soon...",
  "tools": "NextJS, Tailwind and others...",
},
]


export default function Projects() {
  return (
      <Wrapper contentcenter row id='projects'>
        <Fade delay={300} triggerOnce={true}>
        <Section>
          <SectionTitle main color center>Projects</SectionTitle>
          <ProjectText>Here are some of my projects that I´ve been working during my studies and during my free time. I have worked on a lot of projects, but I have decided to only show the ones that I have been able to finish.</ProjectText>
        {/* </Section>

        <Section> */}
        <CardSection>
            {projects.map((item, index) => (
              <Card key={index} {...item}>  
                <CardTitle>{item.title}</CardTitle>
                <img src={process.env.PUBLIC_URL + item.image} alt={item.alt} height="300px"></img>
                <CardText>{item.description}</CardText>
                <CardTech>{item.tools}</CardTech>
                <a href={item.link} target="_blank" rel="noopener noreferrer">View project on Github</a>
                </Card>
            ))}
          </CardSection>
        </Section>
        </Fade>
      </Wrapper>
  )
}
