import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: ${(props) => props.row ? "row" : "column" };
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: ${(props) => props.aligncenter ? "center" : "start" };
    margin-top: 15rem;
    max-width: 100vw;
    overflow: hidden;
    position: relative;

    a {
        color: ${({ theme }) => theme.linkcolor}
    }

    a:hover {
        cursor: pointer;
    }

    a:active {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }

    a:visited {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }
`;


export const ContactStyles = styled.div`
  .form {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
    background-color: transparent;

    @media screen and (max-width: 768px) {
      max-width: 80%;
      display: flex;
      flex-direction: column;
    }
  }

  .input {
    border: 1px solid #ccc;
    background: transparent;
    padding: 0 0 0 0.5rem;
    border-radius: 5px;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color};
    width: 100%;
    height: 4rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 2rem;
      font-size: 1rem;
    }
  }

  .textarea {
    grid-column: 1 / span 2;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 15px;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color};
    width: 100%;
    height: 10rem;
    padding: 1rem;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .button {
    grid-column: 1 / span 2;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 2rem;
    width: 30%;
    background: transparent;
    color: ${({ theme }) => theme.color};
    font-weight: bold;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s;
    margin: 0 auto;
    margin-bottom: 6rem;
    z-index: 99;
    touch-action: manipulation;

    @media screen and (max-width: 768px) {
      width: 30%;
      font-size: 1rem;
      height: 2rem;
      background: transparent;
      margin: 0 auto;
      margin-bottom: 4rem;
    }
  }

  .button:hover {
    opacity: 1;
  }

  .button:active {
    box-shadow: 0 5px #666;
    scale: 0.98;
    transform: translateY(2px);
  }

  .formdone {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
  }
`;
