import React, {useEffect, useState} from "react";

import {
  Wrapper,
  Section,
  SectionTitle,
} from "../../styles/GlobalComponentStyles";
import {
  SectionText,
  TestimonialItem,
  TestimonialName,
  TestimonialImage,
  TestimonialTitle,
  TestimonialText,
  CarouselContainer,
} from "./AboutStyles";
import { Fade } from "react-awesome-reveal";
import * as Scroll from "react-scroll";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

var x = 0;

export default function About() {

  const [person, setPerson] = useState([]);

  const peopleArray = [
    {
      image: "ceciliaengfelt.jpg",
      name: "Cecilia Engfelt",
      title: "Education Manager, Nackademin",
      text: "Zamir is doing a fantastic job as a student representative for the Full Stack Web Developer education through his commitment, solution-oriented mind and high work ethic. It has been a privilege to get to know Zamir, who has extraordinary cooperation, social skills and perseverance. I highly recommend Zamir."
      // text: "Zamir gör en fantastiskt insats som studeranderepresentant för utbildningen webbutvecklare fullstack open source genom sitt engagemang, lösningsorienterade sinne och höga arbetsmoral. Det har varit en förmån att lära känna Zamir, som har en samarbetsförmåga, social kompetens och uthållighet utöver det vanliga. Rekommenderar Zamir det varmaste.",
    },
    {
      image: "evabjorkegren.jpg",
      name: "Eva Björkegren",
      title: "Education Manager, Nackademin",
      text: "In his role as student representative, Zamir has been a great asset to the Full Stack Web Developer education. He has contributed with great commitment to the management team, has always been there for course colleagues and has been a spokesperson for the class. He has also contributed to creating a nice cohesion in the class and is greatly appreciated by all of us."
      // text: "Zamir har i sin roll som studentrepresentant varit en stor tillgång för Webb fullstack open source utbildningen. Han har bidragit med ett stort engagemang i ledningsgruppen, alltid funnits där för kurskollegor och varit språkrör för klassen. Han har också bidragit till att skapa en fin sammanhållning i klassen och uppskattas mycket av oss alla."
    },
    {
      image: "amandanaesman.jpg",
      name: "Amanda Naesman",
      title: "HR Student",
      text: "Zamir is one of the most brilliant students in my class and is good at languages ​​and has great destination knowledge about destinations around the world. Zamir is a specialist in backpacking/adventure travel, is very creative and has extremely good computer skills."
      // text: "Zamir är en av de mest briljanta studerande i min klass och är duktig på språk samt besitter stor destinationskunskap om destinationer runt om i världen. Zamir är specialist på backpacking/äventyrsresande, är väldigt kreativ samt har extremt goda datorkunskaper."
    },
    {
      image: "sebastiankindstrom.jpg",
      name: "Sebastian Kindström",
      title: "Team Leader, Kilroy",
      text: "One of Zamir's greatest strengths was his way of communicating with our customers, both verbally and in writing, and the way he reassured our travelers, this meant that he also had many regular customers. In addition to his obvious ability to give our customers a good service and experience of the company, and himself, Zamir has always been a well-liked and valued colleague."
      // text: "En av de största styrkorna hos Zamir var hans sätt att kommunicera med våra kunder, både i tal och skrift och sättet han tryggade våra resenärer på, detta medförde att han även hade många stamkunder. Utöver sin uppenbara förmåga att ge våra kunder en bra service och upplevelse av företaget, och sig själv, har Zamir alltid varit en omtyckt och uppskattad kollega."
    },
    {
      image: "haydenphelan.jpg",
      name: "Hayden Phelan",
      // title: "Driftchef, Lady Patricia",
      title: "Operations Manager, Lady Patricia",
      text: "Zamir is always calm and collected and handles the pressures of nightclub bartending gallantly. Zamir was respected and popular amongst his peers and the management. He was also much adored by many of our patrons."
    }
  ];
  
  
  
  function nextPerson() {
    if (x < peopleArray.length-1) {
      x++;
      setPerson(peopleArray[x]);
    } else {
      x = 0;
      setPerson(peopleArray[0]);
    }
  }

  function prevPerson() {
    if (x > 0) {
      x--;
      setPerson(peopleArray[x]);
    } else {
      x = peopleArray.length - 1;
      setPerson(peopleArray[x]);
    }
  }

  useEffect(() => {
    setPerson(peopleArray[0]);
    const interval = setInterval(() => {
      nextPerson();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper aligncenter contentcenter row id="about">
      <Fade delay={300} triggerOnce={true}>
        <Section>
          <SectionTitle main color center>
            About me.
          </SectionTitle>
          <SectionText center>
            I am a social team player with a huge interest in new
            experiences and personal development and have a versatile background
            in areas such as technology-, service- and travel industry. <br />
            <br />
            Currently in the process of expanding my skills and currently studying
            Full Stack Web Development at Nackademin, Stockholm, which is a
            2-year higher vocational education.
            <br />
            <br />Looking forward to a career in web development. Please {" "}
            <NavLink to="/about" activeStyle></NavLink>
            <Link><Scroll.Link
              to="contact"
              activeClass="active"
              smooth={true}
              duration={500}
            >contact</Scroll.Link></Link> me 
            for further inquieries and download my CV{" "}
            <Link to="../documents/cv.pdf" target="_blank" download>
              here.
            </Link>
          </SectionText>
        </Section>
      </Fade>
      

      <Section> 
        <Fade delay={300} triggerOnce={true}>
          <CarouselContainer>
            <button onClick={prevPerson}><MdArrowBackIosNew /></button>
            
          <TestimonialItem>
            <TestimonialImage
              src={process.env.PUBLIC_URL + "/images/" + person.image}
              alt={person.name}
            > 
            </TestimonialImage>
            <TestimonialName>{person.name}</TestimonialName>
            <TestimonialTitle>{person.title}</TestimonialTitle>
            <TestimonialText>"{person.text}"</TestimonialText>
          </TestimonialItem>
            <button onClick={nextPerson}> <MdArrowForwardIos /></button>
          </CarouselContainer>
        </Fade>
      </Section>
    </Wrapper>
  );
}
