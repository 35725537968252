import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
   body {
     background: ${({ theme }) => theme.background};
     color: ${({ theme }) => theme.color};
     transition: all 0.50s linear;
  }

  .toggle-switch {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`

