import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: ${(props) => props.row ? "row" : "column" };
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: ${(props) => props.aligncenter ? "center" : "start" };
    margin-top: 15vh;
    max-width: 100vw;
    overflow: hidden;
    position: relative;

    a {
        color: ${({ theme }) => theme.linkcolor}
    }

    a:hover {
        cursor: pointer;
    }

    a:active {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }

    a:visited {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }
`;

export const Section = styled.div`
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    flex-direction: ${(props) => props.row ? "row" : "column" };
    text-align: center;
    margin: 0 2vh 0 2vh;
    
    img {
        max-width: 75%;
        height: auto;
        marging: auto;
        object-fit: cover;
    }
`;

export const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${(props) => props.main ? '4vw' : '2vw'};
  line-height: ${(props) => props.main ? '5.5vw' : '2vw'};
  background: ${(props) => props.color ? 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)' : 'white'};
  text-align: ${(props) => props.center ? "center" : "left" };
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 8vw;
    text-align: center;
    line-height: 8vw;
  }
`

export const SectionText = styled.p`
  max-width: 800px;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 300;
  text-align: ${(props) => props.center ? "center" : "left" };
  color: ${({ theme }) => theme.text};

  @media screen and (max-width: 768px) {
    font-size: 5vw;
    text-align: center;
    line-height: 5vw;

`

export const Block = styled.div`
    width: 75%;
    position: relative;
    margin: 0 auto;
    height: 100%;
`;

export const BlockTitle = styled.h2`
  font-weight: 800;
  font-size: ${(props) => props.main ? '102px' : '48px'};
  line-height: ${(props) => props.main ? '140px' : '60px'};
  width: max-content;
  margin: 0 auto;
  background: ${(props) => props.color ? 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)' : 'white'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
`
export const BlockText = styled.p`
  max-width: 800px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  padding-bottom: 3.6rem;
  color: rgba(255, 255, 255, 0.75);
  margin: 0 auto;
  text-align: center;
`

export const CardSection = styled.div`
  background-color: transparent;
  width: 100vw;
  height: 800px;
  display: flex;
  display: -webkit-flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
  margin-top: 3vw;
  margin-bottom: 50px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    height: 100%;
  }
`

export const Card = styled.div`
background-color: transparent;
width: 50vh;
height: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 1rem 1rem 1rem;
border-radius: 1vh;
box-shadow: ${({ theme }) => theme.shadow};
transition: all 0.2s ease-in-out;
place-content: between;
overflow: hidden;
margin: 0 1rem 0 1rem;

&:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}


  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }


  img {
    width: 45%;
    height: 45%;
    object-fit: cover;
    border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 80vw;
    height: auto;
  }


`

export const CardTitle = styled.h2`
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  color: ${({ theme }) => theme.text};
  margin-bottom: 2rem;
  margin-top: 0;

  @media screen and (max-width: 768px) {
    font-size: 6vw;
    line-height: 4vw;
    margin-bottom: 15px;
  }

`

export const CardText = styled.p`
max-width: 20vw;
margin-top: 2vh;
font-size: 2vh;
line-height: 24px;
font-weight: 300;
text-align: center;
color: ${({ theme }) => theme.text};

@media screen and (max-width: 768px) {
  font-size: 3vw;
  line-height: 4vw;
  margin-bottom: 15px;
  max-width: 60vw;
}
`

export const CardTech = styled.div`
  background-color: transparent;
  width: 100%;
  font-size: 2vh;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 2rem 1rem 2rem;

  a {
    text-decoration: none;
  }


  @media screen and (max-width: 768px) {
    font-size: 3vw;
    line-height: 4vw;
  }

`


export const CardButton = styled.button`
  background: linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%);
  border-radius: 10px;
  padding: 1rem 2rem;
  border: none;
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%);
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
`

export const TechButton = styled.button`
  background: transparent;
  border-radius: 10px;
  padding: 1rem 2rem;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 24px;
  font-weight: 600;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.);
    transition: all 0.2s ease-in-out;
  }
`

export const ProjectText = styled.p`
    max-width: 60%;
    margin: 0 auto;
    font-size: 3vh;
    line-height: 3vh;
    font-weight: 300;

    @media screen and (max-width: 768px) {
      max-width: 80%;
      font-size: 5vw;
      line-height: 5vw;
      text-align: center;
    }

`;