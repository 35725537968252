import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.background};
  height: 10vh;
  display: flex;
  position: fixed;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: space-between;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: ${({ theme }) => theme.transition};
`;

export const ToggleBackground = styled.div`

@media screen and (max-width: 768px) {

  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
  opacity: 0.7;
  z-index: 1;
  transition: all 2s linear;
  }

  .show {
    display: block;
    transition: all 2s linear;
  }
`;

export const NavLink = styled(Link)`
  color: ${({ theme }) => theme.color};
  transition: 0.3s ease-in-out;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    color: #bcbcbc;
    opacity: 1;
    scale: 1.1;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: none;
`;

export const NavLogo = styled.div`
  grid-area: 1 / 1 / 1 / 1;
  display: flex;
  background-color: transparent;
  color: ${({ theme }) => theme.color};
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: calc(12px + 1vw); 
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transition};

  @media screen and (max-width: 768px) {
    font-size: 3vw;
    margin-left: 1vw;
`;

export const NavMenu = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ${({ theme }) => theme.transition};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavSocialMedia = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20%;
  background-color: transparent;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
    margin-left: 1vw;
    display: none;
  }
`;

export const SocialLink = styled.a`
  transition: 0.3s ease-in-out;
  color: ${({ theme }) => theme.color};
  font-size: 2vw;
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.2);
    cursor: pointer;
  }
`;

export const DropdownMenu = styled.div`
  display: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color};
  width: 20%;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
