import styled from "styled-components"; 

export const SectionText = styled.p`
    max-width: 60%;
    margin: 0 auto;
    font-size: 3vh;
    line-height: 3vh;
    font-weight: 300;
    margin-top: 0;

    @media screen and (max-width: 768px) {
        max-width: 80%;
        font-size: 5vw;
        line-height: 5vw;
        text-align: center;
      }

`;

export const TestimonialItem = styled.div`
    background-color: transparent;
    width: 80vw;
    height: 90%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
    padding: 0 1rem 0 1rem;
    border-radius: 1vh;
    box-shadow: ${({ theme }) => theme.shadow};

    @media screen and (max-width: 768px) {
        width: 60vw;
        height: 70%;
        margin: 0 1rem;
        padding: 0 1rem 0 1rem;
        border-radius: 1vh;
        box-shadow: ${({ theme }) => theme.shadow};
    }
`

export const TestimonialImage = styled.img`
    position: absolute;
    margin-top: -50px;
    background: ${({ theme }) => theme.background};
    padding: 1vh;
    width: 10vh;
    height: 10vh;
    object-fit: cover;
    object-position: auto;
    border-radius: 50%;
    transition: ${({ theme }) => theme.transition};

    @media screen and (max-width: 768px) {
        margin-top: -50px;
        padding: 0;  
    }
`;

export const TestimonialName = styled.p`
    margin-top: 9vh;
    font-size: 2vh;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 0;
    color: ${({ theme }) => theme.text};
`;

export const TestimonialTitle = styled.p`
    margin-top: 0;
    background: transparent;
    font-color: grey;
    font-size: 1.5vh;
    line-height: 5vh;
    font-weight: 600;
    color: ${({ theme }) => theme.text};
`;


export const TestimonialText = styled.p`
    margin-top: 0;
    margin-bottom: 2vh;
    font-size: 2vh;
    line-height: 2vh;
    font-weight: 300;
    text-align: center;
    color: ${({ theme }) => theme.text};

`;

export const CarouselContainer = styled.div`
    background: transparent;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem 0 2rem;
    margin-top: 8vh;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0 2rem 0 2rem;
        margin-top: 8vh;
        margin-bottom: 10px;
    }

    button {
        background: transparent;
        color: ${({ theme }) => theme.color};
        border: none;
        border-radius: 50%;
        padding: 1rem;
        margin: 0 1rem;
        cursor: pointer;
        transition: ${({ theme }) => theme.transition};
        box-shadow: ${({ theme }) => theme.shadow};

        &:hover {
            transform: scale(1.1);
            transition: ${({ theme }) => theme.transition};
        }

        @media screen and (max-width: 768px) {
            padding: 1rem;
            margin: 0 1rem;
            margin-top: 1rem;
            display: block;
          }
    }
`;

