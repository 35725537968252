import styled from "styled-components"; 

export const TechTitle = styled.h2`
    font-size: 2vw;
    line-height: 2vw;
    background: linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 5vw;
        line-height: 5vw;
        text-align: center;
      }
`;

export const TechItem = styled.div`
    background-color: transparent;
    width: 50vh
    height: 50vh;
    margin-top: 2rem;
    
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
`;

export const TechSection = styled.div`
    background-color: transparent;
    width: 100%;
    height: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
        width: 100%;
        height: 100%;
        margin: 0 0 0 0;
    }

`;
export const TechCard = styled.div`
    background-color: transparent;
    width: 8vh;
    height: 8vh;
    margin: 1vh 1vh 1vh 2vh;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 1rem;
    border-radius: 1vh;
    align-items: center;
    font-size: 1rem;
    box-shadow: ${({ theme }) => theme.shadow};

    @media screen and (max-width: 768px) {
        width: 5vh;
        height: 5vh;
        margin: 1vh 1vh 1vh 2vh;
        font-size: 0.5rem;
    }
`;

export const TechWrap= styled.div`
    background-color: transparent;
    width: 8vh;
    height: 8vh;
    margin: 1vh 1vh 1vh 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
    border-radius: 1vh;
    align-items: center;
    box-shadow: ${({ theme }) => theme.shadow};
`

export const Wrapper = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: ${(props) => props.row ? "row" : "column" };
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: ${(props) => props.aligncenter ? "center" : "start" };
    margin-top: 15vh;
    max-width: 100vw;
    position: relative;

    a {
        color: ${({ theme }) => theme.linkcolor}
    }

    a:hover {
        cursor: pointer;
    }

    a:active {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }

    a:visited {
        cursor: pointer;
        color: ${({ theme }) => theme.linkcolor2}
    }
`;

export const Section = styled.div`
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    flex-direction: ${(props) => props.row ? "row" : "column" };
    text-align: center;
    margin: 0 2vh 0 2vh;
    
    img {
        max-width: 75%;
        height: auto;
        marging: auto;
        object-fit: cover;
    }
`;

export const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${(props) => props.main ? '4vw' : '2vw'};
  line-height: ${(props) => props.main ? '5.5vw' : '2vw'};
  background: ${(props) => props.color ? 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)' : 'white'};
  text-align: ${(props) => props.center ? "center" : "left" };
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 768px) {
    font-size: 5vw;
    text-align: center;
    line-height: 6vw;
  }
`

export const CardSection = styled.div`
  background-color: transparent;
  width: 100vw;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  gap: 4vh;
  justify-content: center;
  margin-top: 3vw;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`