import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { light } from './styles/Themes';
import { swe } from './styles/Languages';
import { ThemeProvider } from 'styled-components';
import Home from './pages/Home';
import './App.css';
import { GlobalStyles } from './styles/GlobalStyles';


const App = () => {

  const [theme, setTheme] = useState(light);
  const [language, setLanguage] = useState(swe)

  useEffect(() => {
    document.body.className = theme;
    document.body.className = language;
  }, [theme, language]);
  
  return (
    <ThemeProvider theme={theme} language={language}>
      <>
      <GlobalStyles />
      <BrowserRouter> 
        <Routes>
          <Route path="/" element={<Home theme={theme} setTheme={setTheme} language={language} setLanguage={setLanguage} />} />
        </Routes>
      </BrowserRouter>
      </>
    </ThemeProvider>
  );
}

export default App;
