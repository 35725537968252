import React from "react";
import {
  Wrapper,
  Section,
  SectionTitle,
  SectionSubTitle,
  SectionText,
// } from "../../styles/GlobalComponentStyles";
} from "./HeaderStyles";
import TypeWriter from "../Typewriter/Typewriter";
import { Fade } from "react-awesome-reveal";

const typeWriter = [
  "Full Stack Developer",
  "Team Player",
  "Junior Web Developer",
  "Traveller & Explorer",
  "Persistent Student",
  "Mountain climber",
  "Social Person",
  "Marathon Finisher",
  "Curious Person",
  "Programmer / Coder",
  "Husband and a Father",
  "Class Representative",
  "Photographer",
  "Nature Lover",
  "Web Developer",
  "Multilingual person",
  "Problem Solver",
  "Creative Thinker",
  "Learner",
  "Lifelong Adventurer",
];

export default function Header() {
  return (
    <Wrapper id="home">
      <Section> 
      <Fade delay={300} direction="left">
          <SectionTitle main color>
            Welcome to <br /> Zamir Cohen´s <br /> React portfolio.
          </SectionTitle>
          <SectionText>
            I'm a full-stack developer with a passion for creating beautiful,
            functional, and intuitive web applications.
          </SectionText>
          <SectionSubTitle color>
            I am a{" "}
            <span>
              &lt;
              <TypeWriter data={typeWriter} />
            </span>
            &gt;
          </SectionSubTitle>
        </Fade>
        </Section>

      <Section>
      <Fade delay={300} direction="right">
          <img
            src={process.env.PUBLIC_URL + "/images/zamir.png"}
            alt="zamir cohen portrait"
          ></img>
      </Fade>
      
      </Section>
    </Wrapper>
  );
}
