import React from 'react'
import { Wrapper } from '../Footer/FooterStyles'
// import { NavSocialMedia, SocialLink } from '../NavBar/NavbarStyles'; 
// import { FaGithub, FaLinkedin, FaFacebook } from 'react-icons/fa';

export default function Footer() {
  return (
    <Wrapper>
        <div>
        © 2023 Zamir Cohen. All rights reserved.
        </div>
    </Wrapper>
  )
}
