import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: rox;
justify-content: center;
position: relative;
left: 0;
height: 3vh;
bottom: 1vh;
width: 100%;
`;
