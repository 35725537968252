import React from "react";
import "./ToggleButtonStyles.jsx";
import { light, dark } from "../../styles/Themes";
import { ToggleButtonStyles } from "./ToggleButtonStyles.jsx";

const ToggleButton = ({theme, setTheme}) => {

  const toggleTheme = () => {
    if (theme === light) {
      setTheme(dark);
    } else {
      setTheme(light);
    }
  };

  return (
    <ToggleButtonStyles>
    <label className="toggle-switch">
      <input type="checkbox" onClick={toggleTheme}/>
      <span className="switch" />
    </label>
    </ToggleButtonStyles>
  );
}

export default ToggleButton;

