import React from 'react'
import { DiJavascript, DiSass, DiReact, DiMongodb, DiNodejsSmall, DiMysql, DiGit, DiGithubBadge, DiCss3, DiHtml5, DiWordpress, DiVisualstudio } from 'react-icons/di';
import { FiFigma } from 'react-icons/fi';
import { VscAzure } from 'react-icons/vsc';
import { SiNextdotjs, SiExpress, SiTypescript, SiJquery} from 'react-icons/si';
import { Fade } from 'react-awesome-reveal';
import { SectionText } from '../About/AboutStyles';
import { SectionTitle } from "../../styles/GlobalComponentStyles";
import { CardSection, Section, Wrapper, TechItem, TechSection, TechCard, TechTitle} from './TechStyles';

const techFrontend = [
  {
    name: "HTML",
    icon: <DiHtml5 size="10rem" style={{fill:"#e34f26"}}/>,
    link: "https://developer.mozilla.org/en-US/docs/Web/HTML",
    alt: "HTML logo"
  },
  {
    name: "CSS",
    icon: <DiCss3 size="10rem" style={{fill:"#264de4"}}/>,
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    alt: "CSS logo"
  },
  {
    name: "Javascript",
    icon: <DiJavascript size="10rem" style={{fill:"#F0DB4F"}} alt="Javascript logo"/>,
    link: "https://www.javascript.com/",
    alt: "Javascript logo"
  },
  {
    name: "Sass",
    icon: <DiSass size="10rem" style={{fill:"#c69"}}/>,
    link: "https://sass-lang.com/",
    alt: "Sass logo"
  },
  {
    name: "TypeScript",
    icon: <SiTypescript size="10rem" style={{fill:"#007acc"}} alt="Javascript logo"/>,
    link: "https://www.typescriptlang.org",
    alt: "Typescript logo"
  },
  {
    name: "JQuery",
    icon: <SiJquery size="10rem" style={{fill:"#007acc"}}/>,
    link: "https://developer.mozilla.org/en-US/docs/Web/HTML",
    alt: "JQuery logo"
  },
]

const techBackend = [
  {
    name: "Node.js",
    icon: <DiNodejsSmall size="10rem" style={{fill:"#3c873a"}}/>,
    link: "https://nodejs.org/en/",
    alt: "Node.js logo"
  },
  {
    name: "MongoDB",
    icon: <DiMongodb size="10rem" style={{fill:"#47a248"}}/>,
    link: "https://www.mongodb.com/",
    alt: "MongoDB logo"
  },
  {
    name: "MySQL",
    icon: <DiMysql size="10rem" style={{fill:"#4479a1"}}/>,
    link: "https://www.mysql.com/",
    alt: "MySQL logo"
  }, 
  {
    name: "Express",
    icon: <SiExpress size="10rem" style={{fill:"#303030"}}/>,
    link: "https://expressjs.com/",
    alt: "Express logo"
  },
  {
    name: "Javascript",
    icon: <DiJavascript size="10rem" style={{fill:"#F0DB4F"}} alt="Javascript logo"/>,
    link: "https://www.javascript.com/",
    alt: "Javascript logo"
  },
  {
    name: "TypeScript",
    icon: <SiTypescript size="10rem" style={{fill:"#007acc"}} alt="Javascript logo"/>,
    link: "https://www.typescriptlang.org",
    alt: "Typescript logo"
  }
]

const TechFramework = [
  {
    name: "React",
    icon: <DiReact size="10rem" style={{fill:"#61dbfb"}}/>,
    link: "https://reactjs.org/",
    alt: "React logo"
  },
  {
    name: "Next.js",
    icon: <SiNextdotjs size="10rem" style={{fill:"#61dbfb"}}/>,
    link: "https://nextjs.org/",
    alt: "Next.js logo"
  },
  { 
    name: "Wordpress",
    icon: <DiWordpress size="10rem" style={{fill:"#21759b"}}/>,
    link: "https://wordpress.com/",
    alt: "Wordpress logo"
  }
]

const TechTool = [
  {
    name: "Git",
    icon: <DiGit size="10rem" style={{fill:"#f34f29"}}/>,
    link: "https://git-scm.com/",
    alt: "Git logo"
  },
  {
    name: "Github",
    icon: <DiGithubBadge size="10rem" style={{fill:"#181717"}}/>,
    link: "https://git-scm.com/",
    alt: "Github logo"
  },
  {
    name: "Figma",
    icon: <FiFigma size="10rem" style={{fill:"#f24e1e"}}/>,
    link: "https://www.figma.com/",
    alt: "Figma logo"
  },
  {
    name: "Azure",
    icon: <VscAzure size="10rem" style={{fill:"#0089d6"}}/>,
    link: "https://azure.microsoft.com/en-us/",
    alt: "Azure logo"
  },
  {
    name: "Visual Studio",
    icon: <DiVisualstudio size="10rem" style={{fill:"#5c2d91"}}/>,
    link: "https://visualstudio.microsoft.com/",
    alt: "Visual Studio logo"
  }
]



export default function Tech() {
  return (
    <Wrapper aligncenter contentcenter column id="tech">
      <Fade delay={300} triggerOnce={true}>
      
      <Section>
      <SectionTitle main color center>Tech.</SectionTitle>
      <SectionText>Here are some of the technologies I've been working with recently:</SectionText>
        
        <CardSection> 
          <TechItem>
              <TechTitle>Frontend</TechTitle>
            <TechSection>
            {techFrontend.map((tech) => (
              <TechCard key={tech.id}>
                {tech.icon}
                <b>{tech.name}</b>
                </TechCard>
                ))}
            </TechSection>
          </TechItem>

          <TechItem>  
              <TechTitle>Backend</TechTitle>
            <TechSection>
            {techBackend.map((tech) => (
              <TechCard key={tech.id}>
                {tech.icon}
                <b>{tech.name}</b>
                </TechCard>
                ))}
            </TechSection>
          </TechItem>

          <TechItem>
            
              <TechTitle>Framework</TechTitle>
            
            <TechSection>
            {TechFramework.map((tech) => (
              <TechCard key={tech.id}>
                {tech.icon}
                <b>{tech.name}</b>
                </TechCard>
                ))}
            </TechSection>
          </TechItem>

          <TechItem>
              <TechTitle>Tools</TechTitle>
            <TechSection>
            {TechTool.map((tech) => (
              <TechCard key={tech.id}>
                {tech.icon}
                <b>{tech.name}</b>
                </TechCard>
                ))}
            </TechSection>
          </TechItem>

        </CardSection>
      </Section>
      </Fade>
    </Wrapper>
  )
}
