import styled from 'styled-components';
  
export const Button = styled.div`
   position: fixed;
   right: 2vw;
   bottom: 6vh;
   height: 3vh;
   font-size: 5vh;
   z-index: 1;
   cursor: pointer;
   transition: all 0.50s linear;

   &:hover {
      scale: 1.2;
      transition: all 0.50s linear;
   }

   @media screen and (max-width: 1440px) {
      position: fixed;
      right: 4rem;
      bottom: 6vh;
      height: 3vh;
      font-size: 5vh;
      z-index: 1;
      cursor: pointer;
      transition: all 0.50s linear;
   }

   @media screen and (max-width: 1024px) {
      display: none;
   }

`