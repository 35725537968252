import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    margin: 0 5vw 0 5vw;
    margin-top: 20vh;
    position: relative;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 10vh;
    }
`;

export const Section = styled.div`
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    flex-direction: ${(props) => props.row ? "row" : "column" };
    text-align: center;    
    margin: 0 2vh 0 2vh;
    
    img {
        max-width: 70%;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      img {
        max-width: 50%;
      }
`;

export const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${(props) => props.main ? '4vw' : '2vw'};
  line-height: ${(props) => props.main ? '5.5vw' : '2vw'};
  background: ${(props) => props.color ? 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)' : 'white'};
  text-align: ${(props) => props.center ? "center" : "left" };
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 8vw;
    text-align: center;
    line-height: 8vw;
  }
`

export const SectionSubTitle = styled.h2`
  font-weight: 800;
  font-size: ${(props) => props.main ? '4vw' : '2vw'};
  line-height: ${(props) => props.main ? '5.5vw' : '2vw'};
  background: ${(props) => props.color ? 'linear-gradient(121.57deg, #61B9E9 18.77%, #CD7DFF 60.15%)' : 'white'};
  text-align: ${(props) => props.center ? "center" : "left" };
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 5vw;
    text-align: center;
    line-height: 5vw;
  }
`

export const SectionText = styled.p`
  max-width: 800px;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 300;
  text-align: ${(props) => props.center ? "center" : "left" };
  color: ${({ theme }) => theme.text};

  @media screen and (max-width: 768px) {
    font-size: 5vw;
    line-height: 5vw;
    text-align: center;
  }
`